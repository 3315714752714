const state = {
  userInfo: {
    SignCode: "123456789",
    UserAccount: "cxp123",
    UserName: "cxp123",
    OuterSession: "fade9a0d-7cee-49c3-9e1a-de7e986818b1"
  }
}

const mutations = {
  SET_USER_INFO: (state, data) => {
    state.userInfo = data
  }
}

const actions = {
  getUserInfo({commit}, data) {
    commit('SET_USER_INFO', data)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
