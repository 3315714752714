import Vue from 'vue'


import GridCellButtonRender from '@/components/GridList/GridCellButtonRender'
import GridCellLinkRender from '@/components/GridList/GridCellLinkRender'
import GridCheckboxRender from '@/components/GridList/GridCheckboxRender'

import GridSelectRender from '@/components/GridList/GridSelectRender'
import GridDateRender from '@/components/GridList/GridDateRender'
import GridInputRender from '@/components/GridList/GridInputRender'
import GridMouseOverRender from '@/components/GridList/GridMouseoverRender'
import GridCellSelectRender from '@/components/GridList/GridCellSelectRender'
import GridHeaderTitleRender from '@/components/GridList/GridHeaderTitleRender'
import GridInputSearchRender from '@/components/GridList/GridInputSearchRender'
import GridCellShowRender from '@/components/GridList/GridCellShowRender'
import GridCellDateRender from '@/components/GridList/GridCellDateRender'
import GridCellRadioHeaderRender from '@/components/GridList/GridCellRadioHeaderRender'
import GridCellSelectTitle from "@/components/GridList/GridCellSelectTitle";
import GridCellRateRender from "@/components/GridList/GridCellRateRender";

import GridCellRadioRender from "@/components/GridList/GridCellRadioRender";
import GridHenderSortDom from "@/components/GridList/GridHenderSortDom";
import GridHenderSortDom2 from "@/components/GridList/GridHenderSortDom2";

import GridCellFileRender from "@/components/GridList/GridCellFileRender";

import GridCellTextOverflow from "@/components/GridList/GridCellTextOverflow";


Vue.component('GridCellTextOverflow', GridCellTextOverflow)
Vue.component('GridHenderSortDom', GridHenderSortDom)
Vue.component('GridHenderSortDom2', GridHenderSortDom2)

Vue.component('GridCellFileRender', GridCellFileRender)

Vue.component('GridCellButtonRender', GridCellButtonRender)
Vue.component('GridCellLinkRender', GridCellLinkRender)
Vue.component('GridCheckboxRender', GridCheckboxRender)

Vue.component('GridSelectRender', GridSelectRender)
Vue.component('GridDateRender', GridDateRender)
Vue.component('GridInputRender', GridInputRender)
Vue.component('GridMouseOverRender', GridMouseOverRender)
Vue.component('GridCellSelectRender', GridCellSelectRender)
Vue.component('GridHeaderTitleRender', GridHeaderTitleRender)
Vue.component('GridInputSearchRender', GridInputSearchRender)
Vue.component('GridCellShowRender', GridCellShowRender)
Vue.component('GridCellDateRender', GridCellDateRender)
Vue.component('GridCellRadioHeaderRender', GridCellRadioHeaderRender)
Vue.component('GridCellSelectTitle', GridCellSelectTitle)
Vue.component('GridCellRateRender', GridCellRateRender)
Vue.component('GridCellRadioRender', GridCellRadioRender)
