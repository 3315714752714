<template>
  <div>
    <el-checkbox v-model="params.data.isAllCheck" @change="onChange"></el-checkbox>
  </div>
</template>
<script>
export default {
  name: 'GridCellRadioRender',
  data() {
    return {
    }
  },
  methods: {
    onChange(val) {
      const {data, colDef} = this.params
      const params = {
        index: data.rowIndex,
        code: colDef.field,
        isCheck: val
      }
      this.params.onChange(params)
    }
  },
  created() {

  }
}
</script>
