<template>
  <div>
    <!-- <el-tooltip class="item" effect="dark" content="11" placement="top"> -->
         {{params.value}}
    <!-- </el-tooltip> -->

  </div>
</template>
<script>
export default {
  name: "GridCellTextOverflow",
  computed: {},
  data() {
    return {

    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.params.click();
      this.title = this.params.displayName;
       console.log('111 this.params ',this.params.value);

    },
    // click(data) {
    //   this.state = data;
    //   let { title, state } = this;
     
    //   this.params.click({
    //     title,
    //     state,
    //   });
    // },
  },
};
</script>
