<template>
  <div>
    <a class='grid-cell-file' v-if="clearText"
       @click="onClick()">
      <span>{{clearText}}</span>
    </a>
    <el-image v-else
      style="width: 100px; height: 100px"
      :src="url"
      :preview-src-list="srcList">
    </el-image>
  </div>
</template>

<script>
export default {
  name: 'GridCellFileRender',
  computed: {
    clearText() {
      const { params } = this
      const { value } = params
      if (!value)
        return '无文件'
      if(value.indexOf('.xls') >= 0 || value.indexOf('.xlsx') >= 0) {
        return '下载表格'
      }
      return '';
    },
    url() {
      const { params } = this
      return params.value
    },
    srcList() {
      const { params } = this
      return [params.value]
    }
  },
  methods: {
    onClick() {
      window.open(this.url)
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-cell-file{
  cursor: pointer;
  color: #28C;
  text-decoration: underline;
}
</style>
