<template>
  <a class='grid-cell-link'
    @click="onClick()">
    <span>{{clearText}}</span>
  </a>
</template>

<script>
  export default {
    name: 'GridCellLinkRender',
    computed: {
      clearText() {
        const { params } = this
        const { textFormatter } = params
        if (textFormatter) {
          return textFormatter(params)
        }

        const { value } = params
        return value
      }
    },

    methods: {
      onClick() {
        const { click } = this.params
        if (click) {
          click(this.params)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.grid-cell-link {
  cursor: pointer;
  color: #28C;
  text-decoration: underline;
}
</style>