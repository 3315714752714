import {getSystemInfo, getSystemDepart} from "@/api/common";
import {
  getDict
} from "@/api/config";

const state = {
  SystemUser: [],
  SystemDepart: [],
  CurrencyOptions: [],
  TaxRatesOptions: []
}

const mutations = {
  SET_SYSTEM_USER: (state, data) => {
    state.SystemUser = data
  },
  SET_SYSTEM_DEPART: (state, data) => {
    state.SystemDepart = data
  },
  SET_CURRENCY_OPTIONS: (state, data) => {
    state.CurrencyOptions = data
  },
  SET_TAX_RARE_OPTIONS: (state, data) => {
    state.TaxRatesOptions = data
  }


}

const actions = {
  async setSystemUser({commit}) {
    const data = await getSystemInfo()
    const userData = data.Items
    commit('SET_SYSTEM_USER', userData)
  },

  async setSystemDepart({commit}) {
    const data = await getSystemDepart()
    const departData = data.Items
    commit('SET_SYSTEM_DEPART', departData)
  },

  async setCurrencyOptions({commit}) {
    const data  = await getDict({keyword: 'Currency'})
    commit('SET_CURRENCY_OPTIONS', data)
  },

  async setTaxRatesOptions({commit}) {
    let taxRates = []
    let data  = await getDict({keyword: 'TaxRate'})
    if (data && data.length > 0) {
      // 值为 int 类型， 下拉列表 key 为 string 类型， 无法匹配
      taxRates = data.map(a => Object.assign({key: Number(a.key), value: a.value}))
    }
    commit('SET_TAX_RARE_OPTIONS', taxRates)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
