import request from '@/utils/request'



export function createSession() {
  return request({
    url: '/SmsCaptcha/CreateSession',
    method: 'get'
  })
}

export function getImgCode(params) {
	return request({
		url: '/SmsCaptcha/getImage',
		method: 'get',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		responseType: 'arraybuffer', // 关键 设置 响应类型为二进制流
		params
	}).then(data => {

		return 'data:image/png;base64,' + btoa(
			new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), '')
		)
	})
}

export function getInfo() {
  return request({
    url: '/user/getCurrent',
    method: 'get'
  })
}

export function changePwd(data) {
	return request({
		url: '/user/changePassword',
		method: 'post',
		data
	})
}

export function verifySms(data) {
	return request({
		url: '/user/verifySms',
		method: 'post',
		data
	})
}
export function registerVerifySms(data) {
	return request({
		url: '/user/Register',
		method: 'post',
		data
	}).then(data => {
		return data
	})
}

export function forgetChangePassword(data) {
	return request({
		url: '/user/forgetChangePassword',
		method: 'post',
		data
	})
}

export function sendSms(data) {
  return request({
    url: '/SmsCaptcha/Send',
    method: 'post',
    data
  })
}

export function VerifyCompany(data) {
	return request({
	  url: '/user/VerifyCompany',
	  method: 'post',
	  data
	})
}


export function signInWeb(data) {
  return request({
    url: '/Security/signInWeb',
    method: 'post',
    data
  })
}

export function setErpSignIn(data) {
  return request({
    url: 'Security/ErpSignIn',
    method: 'post',
    data
  })
}
export function GetUserList(data) {
	return request({
		url: 'User/GetList',
		method: 'post',
		data
	})
}

export function LockUser(data) {
	return request({
		url: 'User/Lock',
		method: 'post',
		data
	})
}
export function GetUserRole(data) {
	return request({
		url: 'User/GetUserRole',
		method: 'post',
		data
	})
}
export function SaveUser(data) {
	const { Id } = data
	if (!Id) {
		return AddUser(data)
	}
	return UpdateUser(data)
}

export function AddUser(data) {
	return request({
		url: 'User/Add',
		method: 'post',
		data
	})
}
export function UpdateUser(data) {
	return request({
		url: 'User/Update',
		method: 'post',
		data
	})
}





