
import axios from 'axios'
import qs from 'qs'
import {typeOf} from './index'
import {Message} from 'element-ui'
import {getToken, removeToken} from './auth'
import {getApiBaseUrl} from "./urls";
import {setLogin} from "./login";
import {isClient} from "./tool";
import weblogger from '@/utils/weblogger'

// 创建一个axios实例
const instance = axios.create({
  baseURL: getApiBaseUrl(),
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8;multipart/form-data',
    'X-Token': getToken()
  }
})


// create an axios instance
const service = axios.create({
  baseURL: getApiBaseUrl(),
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8;multipart/form-data'
  },
  transformRequest: function (data) {
    if (typeOf(data) === 'String' || typeOf(data) === 'FormData') {
      return data
    }
    // 过滤空字段
    // for (const prop in data) {
    //   if (!data[prop]) {
    //     delete data[prop]
    //   }
    // }
    return JSON.stringify(data)
  },
  paramsSerializer: function (params) {
    if (typeOf(params) === 'String') {
      return params
    }
    for (const prop in params) {
      if (!params[prop]) {
        delete params[prop]
      }
    }
    return qs.stringify(params, {indices: false})
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    const token = getToken()
    if (token !== undefined) {
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  async response => {
    const { code, data, msg, tip } = response.data
    if (code !== 0 && tip){
	    Message({
		    message: msg,
		    type: 'error',
		    duration: 5 * 100000
	    })
	    return {}
    }

    if (tip) {
	    return data
    }

    return response.data
  },
  async error => {
    console.log('error',error)
    const { status, data } = error.response
	  let { msg, tip, errors } = data
    if (status === 400) {
      if (errors !== undefined) {
        // JSON format errors
        Message({
          message: '数据格式错误',
          type: 'error',
          duration: 5 * 1000
        })
		      const { config } = error
		      const { data, url } = config
		      const wl = {
				      Token: getToken(),
				      RequestJson: data,
				      ApiUrl: url
		      }
		      await weblogger({ data: wl })
        return Promise.reject({
          msg: '数据格式错误',
          ...data
        })
      }
      if (tip === false) {
        return Promise.reject(msg)
      }
    }

    if (status === 401) {
      removeToken()
      if (isClient()) {
        await setLogin()
      }

      return Promise.reject(error)
    }

    if (status >= 500) {
      msg = '服务器繁忙'
    }

    if (msg) {
      Message({
        message: msg,
        type: 'error',
        duration: 5 * 1000
      })
    }

    return Promise.reject(error)
  }
)

export default service
