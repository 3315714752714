import Vue from 'vue'

Vue.prototype.$ELEMENT = {
  size: 'mini',
  zIndex: 2000
}

Vue.prototype.$notify = function (option) {
  delete option.position
  Element.Notification({
    duration: 3000,
    showClose: false,
    position: 'top-right',
    customClass: 'custom-el-notification',
    ...option
  })
}


// Vue.prototype.$ELEMENT.Input.props.clearable.default = true
// Vue.prototype.$ELEMENT.Dialog.props.closeOnClickModal.default = false

// import Element from 'element-ui'

// window.Element.Input.props.clearable.default = true
// window.Element.Dialog.props.closeOnClickModal.default = false
// window.Element.size = 'mini'
// Vue.use(Element, {
//   size: 'mini'
// })
// Vue.prototype.$ELEMENT.size = 'mini'
// Vue.prototype.$notify = function (option) {
//   delete option.position
//   Element.Notification({
//     duration: 3000,
//     showClose: false,
//     position: 'top-right',
//     customClass: 'custom-el-notification',
//     ...option
//   })
// }
