<template>
  <div>
    <div v-if="isChange">
      <el-input placeholder="" v-model="amount" :disabled="isDisabled" class="input-with-select">
        <el-button slot="append" @click="onChange">{{isDisabled? '修改' : '保存'}}</el-button>
      </el-input>
    </div>
    <div v-if="!isChange" style="margin-left: 12px">
      <span>{{amount}}</span>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'GridInputRender',
    data() {
      return {
        amount: 2000,
        isDisabled: true,
        oldAmount: 0,
        maxAmount: 0,
        isChange: true
      }

    },
    methods: {
      onChange() {
        if (!this.isDisabled) {
          const data = this.params.data
          const totalAmount = Number(this.maxAmount) + Number(this.oldAmount)
          if(this.amount > totalAmount) {
            this.$message.warning('金额不能大于未认领总额')
            this.amount = totalAmount
            return
          }
          this.params.onChange(this.amount, data)
        }
        this.isDisabled = !this.isDisabled
      }
    },
    created() {
      if(this.params.data.RowIndex === '合计') {
        this.isChange = false
      }
      this.amount = this.params.value
      this.oldAmount = this.params.value
      this.maxAmount = this.params.data.EnableClaimAmount
    }
  }
</script>