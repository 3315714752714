<template>
  <div class="p-grid-date">
    <p class="p-grid-title">{{params.displayName}}</p>
    <el-date-picker
      v-model="data"
      :clearable="true"
      type="date"
      format="yyyy/MM/dd"
      class="c-date"
      @change="onChange"
      placeholder="时间">
    </el-date-picker>
  </div>
</template>
<script>
  export default {
    name: 'GridDateRender',
    data() {
      return {
        data: '',
      }
    },
    computed: {},
    methods: {
      onChange() {
        this.params.onChange(this.data)
      }
    },
    created() {
      this.data = this.params.initData
    }
  }
</script>
<style lang="scss">
  .p-grid-date {
    width: 100%;

    .el-input__prefix {
      display: none;
    }
    .el-input__inner {
      padding: 0 !important;
      width: 100% !important;
      height: 18px !important;
      line-height: 18px !important;
      border-radius: 0;
      font-size: 10px;
    }
    .el-input__icon::before{
      position: absolute !important;
      top: -8px !important;
      right: -3px !important;
    }
  }

  .p-grid-title {
    text-align: left;

  }

  .d {
    /*display: none;*/
  }

</style>