import {asyncRoutes, constantRoutes} from '@/router'

function hasPermission(permission, route) {
  if (route.meta && route.meta.Code) {
    return permission.some(item => route.meta.Code === item.Code)
  } else {
    return false
  }
}

export function filterAsyncRoutes(routes, permission) {

  const res = []
  routes.forEach(route => {
    const tmp = {...route}
    // if (hasPermission(permission, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permission)
      }
      res.push(tmp)
    // }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: []
}
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}
const actions = {
  generateRoutes({commit}, permission) {
    return new Promise(resolve => {
      let accessedRoutes = filterAsyncRoutes(asyncRoutes, permission)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
