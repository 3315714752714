<template>
  <div>
    <el-checkbox v-model="params.data[params.field]" @change="() => params.change(params)"></el-checkbox>
  </div>
</template>
<script>
  export default {
    name: 'GridCheckboxRender',
    methods: {
      // onChange() {
      //
      // }
    },
    created() {

    }
  }
</script>