import axios from 'axios'
import {getToken, removeToken} from './auth'
import {getApiBaseUrl} from "./urls";
import {setLogin} from "./login";
import {isClient} from "./tool";

// create an axios instance
const weblogger = axios.create({
		baseURL: getApiBaseUrl() + 'SystemInfo/InsertWebLogger',
		method: 'post',
		timeout: 50000,
		headers: {
				'Content-Type': 'application/json;charset=UTF-8'
		}
})

// request interceptor
weblogger.interceptors.request.use(
		config => {
				const token = getToken()
				if (token !== undefined) {
						config.headers['Authorization'] = token
				}
				return config
		},
		error => {
				return Promise.reject(error)
		}
)

export default weblogger
