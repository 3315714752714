import LayoutErp from '@/layout/LayoutErp'
const clientManageRouter = {
    path: '/clientManage',
    component: LayoutErp,
    redirect: 'noRedirect',
    name: 'clientManage',
    meta: {
        title: '客户管理',
        icon: 'bussiness-man',
        Code: 'client_manage:view',
    },
    children: [{
            path: 'listView',
            name: 'clientList',
            component: () =>
                import ('@/views/clientManage/listView.vue'),
            meta: { title: '客户列表', erp: true, affix: true, icon: 'address-book', Code: 'client_manage_list:view', }
        },
        {
            path: 'maintainView',
            name: 'clientMaintain',
            component: () =>
                import ('@/views/clientManage/maintainView.vue'),
            meta: { title: '客户维护', icon: 'accounts', Code: 'client_manage_maintain:view' }
        },
        {
            path: 'takeView',
            name: 'clientTake',
            component: () =>
                import ('@/views/clientManage/takeView.vue'),
            meta: { title: '客户盘点', icon: 'account-set', Code: 'client_manage_take:view' }
        },
        {
            path: 'auditClientView',
            name: 'auditClient',
            component: () =>
                import ('@/views/clientManage/auditClientView.vue'),
            meta: { title: '客户审核', icon: 'certified-supplier', Code: 'client_manage_audit:view' }
        },
        {
            path: 'CustomerConfigView',
            name: 'CustomerConfig',
            component: () =>
                import ('@/views/clientManage/CustomerConfigView.vue'),
            meta: { title: '客户配置', icon: 'accounts', Code: 'client_manage_CustomerConfig:view' }
        },
        {
            path: 'editClient/:clientId',
            name: 'editClient',
            hidden: true,
            component: () =>
                import ('@/views/clientManage/editClientView.vue'),
            meta: { title: '编辑客户', erp: true, Code: 'client_manage_list:edit' }
        },
        {
            path: 'newClient',
            name: 'newClient',
            hidden: true,
            component: () =>
                import ('@/views/clientManage/newClientView.vue'),
            meta: { title: '新增客户', erp: true, Code: 'client_manage_list:add' }
        },
        {
            path: 'detailClient/:clientId',
            name: 'detailClient',
            hidden: true,
            component: () =>
                import ('@/views/clientManage/editClientView'),
            meta: { title: '客户详情', erp: true, Code: 'client_manage_list:detail' }
        },
        {
            path: 'followClient/:clientId',
            name: 'followClient',
            hidden: true,
            component: () =>
                import ('@/views/clientManage/followClientView'),
            meta: { title: '写跟进', erp: true, Code: 'client_manage_list:write_follow' }
        },
        {
            path: 'mergeClient',
            name: 'mergeClient',
            hidden: true,
            component: () =>
                import ('@/views/clientManage/mergeClientView'),
            meta: { title: '合并客户', Code: 'client_manage_list:merge_client' }
        }

    ]
}

export default clientManageRouter