<template>
  <div>
    {{ title }} 

      <template >
			<i class="el-icon-arrow-up" @click="click(1)"></i>

			<i class="el-icon-arrow-down" @click="click(0)"></i>
		</template>

  </div>
</template>
<script>
export default {
  name: "GridHenderSortDom",
  computed: {},
  data() {
    return {
      title: null,
      state: 0, // 1 升序 ， 0 倒序
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.params.click();
      this.title = this.params.displayName;
    },
    click(data) {
      this.state = data;
      let { title, state } = this;
      this.params.click({
        title,
        state,
      });
    },
  },
};
</script>
