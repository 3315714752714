const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.userInfo.token,
	avatarUrl: state => state.user.userInfo.avatarUrl,
  userId: state => state.user.userInfo.userId,
  name: state => state.user.userInfo.name,
	nickName: state => state.user.userInfo.nickName,
  companyName: state => state.user.userInfo.companyName,
  errorLogs: state => state.errorLog.logs,
  permission_routes: state => state.permission.routes,
  currencyOption: state => state.common.CurrencyOptions,
  taxRatesOptions: state => state.common.TaxRatesOptions
}
export default getters
