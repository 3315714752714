export function cancan(store) {
  return  {
    bind(el, binding, vNode) {
      const { value } = binding
      const { permissionMap } = store.state.user

      const allow = permissionMap[value] || false

      if (!allow) {
        commentNode(el, vNode, value)
      }
    }
  }
}

function commentNode(el, vNode, permission) {
  const comment = document.createComment(`permission: ${permission}`)

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })

  vNode.text = ''
  vNode.elm = comment
  vNode.isComment = true
  vNode.context = undefined
  vNode.tag = undefined
  vNode.data.directives = undefined

  if (vNode.componentInstance) {
    vNode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}
