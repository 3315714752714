<template>
  <div :class="{ 'has-logo': showLogo }" class="p-side-bar">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :active-text-color="variables.menuActiveText"
        :background-color="variables.menuBg"
        :collapse="isCollapse"
        :collapse-transition="false"
        :default-active="activeMenu"
        :text-color="variables.menuText"
        :unique-opened="false"
        mode="vertical">
        <sidebar-item v-for="(route, index) in routes" :key="`${route.path}_${index}`" :base-path="route.path"
                      :item="route"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: {SidebarItem, Logo},
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar',
    ]),
    routes() {

      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const {meta, path} = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  created() {

  },
  mounted() {

  }
}
</script>
<style lang="scss">
.p-side-bar {
  .el-menu-item, .el-submenu__title {
    height: 45px;
    line-height: 45px;
  }
  .el-submenu .el-menu-item {
    padding: 0 40px !important;
  }
}

</style>
