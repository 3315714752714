/**
 * 判断是否嵌套在ERP客户端里面
 * @returns 嵌套结果
 */
export const isClient = () => window.cefQuery !== undefined

/**
 * 获取客户端的授权信息
 * @returns 授信信息
 */
export async function getErpAuthInfo () {
  const signal = 'getErpAuthInfo'
  let param = {}
  const res = await nativeInvoke(signal, param)
  return res
}

export function nativeInvoke(service, data = {}) {
  if (!isClient()) {
    return Promise.reject('操作失败，该操作需要ERP软件协助')
  }

  try {
    return new Promise((resolve, reject) => {
      const content = JSON.stringify(data)
      window.cefQuery({
        request: service,
        data: content,
        onSuccess: res => resolve(JSON.parse(res)),
        onFailure: (code, err) => reject({
          code,
          err,
          type: 'cefQuery',
          request: {
            service,
            data
          }
        }),
        persistent: false
      })
    })
  } catch (e) {
    return Promise.reject(e)
  }
}


