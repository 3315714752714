<template>
  <div @mouseenter="onEnter" @mouseleave="onLeave" class="m-grid-mouse">
    <p>{{value}}</p>
    <p class="m-grid-mouse-btn" v-if="isShow">
      <znl-button @click="onClick">修改</znl-button>
    </p>
  </div>
</template>
<script>
  export default {
    name: 'GridMouseOverRender',
    data () {
      return {
        data: null,
        isShow: false,
        isMouse: false,
        value: ''
      }
    },
    methods: {
      onEnter () {
        if (this.isMouse) {
          this.isShow = true
        }
      },
      onLeave () {
        this.isShow = false
      },
      onClick () {
        this.params.onChange(this.data, true)
      }

    },
    created () {
      this.data = this.params.data
      this.isMouse = this.params.isMouse
      this.value = this.params.value
    }
  }
</script>
<style lang="scss">
  .m-grid-mouse {
    position: relative;
    height: 100%;
    .m-grid-mouse-btn {
      position: absolute;
      top: 0;
      right: 2px;
    }
  }
</style>
