import Vue from 'vue'

import 'normalize.css/normalize.css'
import '@/styles/index.scss'

import App from './App'
import store from './store'
import router from './router'

import './icons'
import './permission'
import './utils/error-log'
import './plugins'
import './directive'

import { cancan } from '@/premission/directive'
import { cancanMixins } from '@/premission/cancan'
const can = cancan(store)
Vue.directive('can', can)
Vue.prototype.can = cancanMixins.can
Vue.prototype.$EventBus = new Vue()

// 全局过滤器
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


Vue.prototype.$IsProd = process.env.NODE_ENV === 'production'
Vue.prototype.$log = function () {
	process.env.NODE_ENV === 'development' && console.log.call(this, ...arguments)
}
Vue.prototype.$isClient = window.cefQuery !== undefined

Vue.prototype.$originUrl = function () {
  return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_STATIC_FILES : location.origin
}

Vue.config.productionTip = false

new Vue({
	el: '#app',
	router,
	store,
  render: h => h(App)
})




