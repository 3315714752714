//1.格式化日期
export const formatDate = (date, fmt) => {
  if (date === null || date === undefined || date === '') {
    return ''
  }
  let dateData = new Date(date)
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (dateData.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': dateData.getMonth() + 1,
    'd+': dateData.getDate(),
    'h+': dateData.getHours(),
    'm+': dateData.getMinutes(),
    's+': dateData.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
    }
  }

  function padLeftZero(str) {
    return ('00' + str).substr(str.length)
  }

  return fmt
}

// 2. 接口数据转化 start
export const setDataMap = (data, map, type) => {
  let item = {}
  Object.entries(map).forEach(([key, src]) => {
    if (type === 'take') {
      item[key] = data[src]
    }
    if (type === 'save') {
      item[src] = data[key]
    }
  })
  return item
}

export const getListData = (lines, listMap) => {
  let newData = []
  if (lines.length) {
    newData = lines.map(line => {
      return setDataMap(line, listMap, 'take')
    })
  }
  return newData
}
export const setListData = (lines, listMap) => {
  let newData = []
  if (lines.length) {
    newData = lines.map(line => {
      return setDataMap(line, listMap, 'save')
    })
  }
  return newData
}

// end

// 3. 日期转化 start
// 获取本周
export const getWeek = (item) => {

  //按周日为一周的最后一天计算
  let date = new Date(item);
  //今天是这周的第几天
  let today = date.getDay();
  //上周日距离今天的天数（负数表示）
  let stepSunDay = -today + 1;
  // 如果今天是周日
  if (today === 0) {
    stepSunDay = -7;
  }

  // 周一距离今天的天数（负数表示）
  let stepMonday = 7 - today;

  let time = date.getTime();


  let monday = new Date(time + stepSunDay * 24 * 3600 * 1000);
  let sunday = new Date(time + stepMonday * 24 * 3600 * 1000);

  //本周一的日期 （起始日期）
  let startDate = formatDate(monday, 'yyyy-MM-dd'); // 日期变换
  //本周日的日期 （结束日期）
  let endDate = formatDate(sunday, 'yyyy-MM-dd'); // 日期变换

  return {
    weekStart: startDate,
    weekEnd: endDate
  }
}
// 获取本月
export const getMonth = (item) => {
  let weekItems = []
  const oneDateNum = 24 * 60 * 60 * 1000

  const newTime = new Date(item)
  // 年
  const year = newTime.getFullYear()
  // 月
  const month = newTime.getMonth()
  // 当前月的第一天
  let monthStartDate = newTime.setDate(1);
  // 当前月的最后一天
  const monthEndData = new Date(year, month + 1, 0)

  let i = 1

  // 当前月的第一天是星期几
  let firstWeekStartDate = new Date(monthStartDate).getDay()
  // 该月第一周结束日期
  let firstWeekEndDate = firstWeekStartDate === 0 ? monthStartDate : (monthStartDate + (7 - firstWeekStartDate) * oneDateNum)

  weekItems.push({
    index: i,
    startTime: new Date(monthStartDate),
    endTime: new Date(firstWeekEndDate)
  })


  while (firstWeekEndDate < monthEndData) {
    i++;
    monthStartDate = (firstWeekEndDate + oneDateNum);
    firstWeekEndDate = firstWeekEndDate + 7 * oneDateNum > monthEndData ? monthEndData : firstWeekEndDate + 7 * oneDateNum;
    weekItems.push({
      index: i,
      startTime: new Date(monthStartDate),
      endTime: new Date(firstWeekEndDate)
    })
  }
  return weekItems;
}

// 季度
export function getQuarter(time) {
  const newTime = new Date(time)

  const year = newTime.getFullYear()

  const month = newTime.getMonth()

  const nowQuarter = parseInt(month / 3);

  const frontQuarterEndMonth = new Date(year, nowQuarter * 3, 0).getMonth();

  let quarterMonth = []

  for (let i = 1; i < 4; i++) {
    quarterMonth.push(frontQuarterEndMonth + i)
  }

  const quarterItems = []

  quarterMonth.forEach((month, index) => {
    const newDate = new Date(year, month)
    const startDate = new Date(newDate.setDate(1))
    const endDate = new Date(year, month + 1, 0)

    quarterItems.push({
      index: index + 1,
      startTime: startDate,
      endTime: endDate
    })
  })


  return quarterItems
}


export const isClient = () => window.cefQuery !== undefined
