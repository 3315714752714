import Layout from '@/layout/index.vue'
console.log(Layout)

const commonRouter = {
    path: '/common',
    component: Layout,
    redirect: 'noRedirect',
    name: 'common',
    meta: {
        title: '公海',
        icon: 'earth',
        Code: 'common:view'
    },
    children: [{
        path: 'commonListView',
        name: 'CommonListView',
        component: () =>
            import ('@/views/common/listView.vue'),
        meta: { title: '公海列表', icon: 'export_services' }
    }]
}

export default commonRouter