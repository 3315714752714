<template>
  <div class="c-grid-rate-view">
    <section>
      <svg-icon
        v-for="item in rateItems"
        :key="item.key"
        :icon-class="item.isSelect? 'rate-select': 'rate-default'"
        class="c-rate c-rate-default">
      </svg-icon>
    </section>
  </div>
</template>

<script>
export default {
  name: 'GridCellRateRender',
  data() {
    return {
      rateItems: [
        {
          key: 1,
          isSelect: false,
        },
        {
          key: 2,
          isSelect: false,
        },
        {
          key: 3,
          isSelect: false,
        },
        {
          key: 4,
          isSelect: false,
        },
        {
          key: 5,
          isSelect: false,
        },

      ]
    }
  },
  methods: {

  },
  created() {
    const value = this.params.value
    const newCount = Number(value)
    this.rateItems.forEach(m => {
      m.isSelect = m.key <= newCount;
    })
  },
}
</script>
<style lang="scss">
.c-grid-rate-view {
  .c-rate {
    font-size: 12px;
    width: 1em;
    height: 1em;
  }
  .c-rate-default {
    color: #999999;
  }
}
</style>

