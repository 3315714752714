<template>
  <div>
    {{ title }} 

      <template >
			<label>{{labledata}}</label>
		</template>

  </div>
</template>
<script>
export default {
  name: "GridHenderSortDom2",
  computed: {},
  data() {
    return {
      title: null,
      labledata:0
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.params.click();
      this.title = this.params.displayName;
      this.labledata=this.params.ScoreInfos
    },
    // click(data) {
    //   debugger
    //   this.state = data;
    //   let { title, state } = this;
    //   this.params.click({
    //     title,
    //     state,
    //   });
    // },
    
  },
};
</script>
