<template>
  <div class="p-grid-input-search">
    <p class="p-grid-title">{{params.displayName}}</p>
    <el-input clearable v-model="data" @change="onChange" size="mini"></el-input>
  </div>
</template>
<script>
  export default {
    name: 'GridRadioRender',
    data () {
      return {
        data: ''
      }
    },
    computed: {},
    methods: {
      onChange () {
        this.params.onChange(this.data)
      }
    },
    created () {
    }
  }
</script>
<style lang="scss">
  .p-grid-input-search {
    .el-input__inner {
      height: 19px !important;
      padding: 0 5px;
      border-radius: 0;
    }
  }

</style>
