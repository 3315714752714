<template>
  <div class="my-grid-cell-select">
    <el-select
      size="mini"
      v-model="value"
      filterable
      clearable
      :default-first-option="true"
      @change="onChangeValue"
      placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.key"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  export default {
    name: 'GridCellSelectRender',
    data () {
      return {
        value: '',
        data: {},
        options: [],
      }
    },
    computed: {},
    methods: {
      onChangeValue (val) {
        const data = this.data
        this.params.onChange(data, val)
      }
    },
    created () {
      const options = this.params.options
      const data = this.params.data
      options.forEach(item => {
        if (item.key === this.params.value) {
          this.value = item.value
        }
      })
      this.data = data
      this.options = options

    }

  }
</script>

<style lang="scss">
  .my-grid-cell-select {
    .el-input__inner {
      height: 25px !important;
      line-height: 25px !important;
      border: none;
      padding: 0 !important;
      border-radius: 0;
    }
    .el-input__suffix {
      right: -3px;
    }

    .el-select {
      width: 100%;
    }
  }
</style>
