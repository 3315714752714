<template>
  <div class="c-go-back" @click="onGoBack">
      <i class="el-icon-arrow-left"></i>
      <span>返回</span>
  </div>
</template>
<script>
export default {
  name: 'BreadcrumbContent',
  data() {
    return {

    }
  },
  methods: {
    onGoBack() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
.c-go-back {
  font-size: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  color: #888888;
}
</style>
