const stringify = (data) => {
	if (!data) return
	const type = ofType(data)
	if (type !== 'Object' && type !== 'Array') return data
	return JSON.stringify(data)
}

const ofType = (val) => {
	return Object.prototype.toString.call(val).replace('[object ', '').replace(']', '')
}

const parse = (data) => {
	if (!data) return
	try {
		return JSON.parse(data)
	} catch {
		return data
	}
}

export const setCookies = (name, options) => {
	const value = stringify(options)
	localStorage.setItem(name, value)
}

export const getCookies = (name) => {
	const value = localStorage.getItem(name)
	if (!value) return null
	return parse(value)
}

export const removeCookies = (name) => {
	localStorage.removeItem(name)
}
