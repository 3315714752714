// 过滤客户类型
const ClientOptions = [
  {key: 'AddInquiry', value: '新增询价客户'},
  {key: 'Commonly', value: '一般客户'},
  {key: 'StressFollow', value: '重点跟进客户'},
  {key: 'StressProtect', value: '重点保护客户'},
  {key: 'Assist', value: '协助客户'},
  {key: 'Subordinate', value: '下属客户'}
]
const followOptions = [
  {
    key: 'ComeVisit',
    value: '上门拜访'
  },
  {
    key: 'PhoneVisit',
    value: '电话拜访'
  },
  {
    key: 'QQContact',
    value: 'QQ联系'
  },
  {
    key: 'MailContact',
    value: '邮件联系'
  },
		{
				key: 'WeChatContact',
				value: '微信联系'
		}
]

export function clientTypeFilter(value) {
  const item = ClientOptions.find(k => k.key === value)
  let clientTypeName = ''
  if (item) {
    clientTypeName = item.value
  }
  return clientTypeName
}

export function followTypeFilter(value) {
  const item = followOptions.find(k => k.key === value)
  let followTypeName = ''
  if (item) {
    followTypeName = item.value
  }
  return followTypeName
}
