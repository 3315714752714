import Layout from '@/layout/index.vue'
//import { CommonConfigEdit } from '~/views/config/modules/commonConfig/CommonConfigEdit'
const configRouter = {
    path: '/config',
    component: Layout,
    redirect: 'noRedirect',
    name: 'config',
    meta: {
        title: '配置参数',
        icon: 'set',
        Code: 'config:view'
    },
    children: [{
            path: 'dictView',
            name: 'configDict',
            component: () =>
                import ('@/views/config/dictView.vue'),
            meta: { title: '通用字典', icon: 'form' }
        },
        {
            path: 'tagView',
            name: 'configTag',
            component: () =>
                import ('@/views/config/tagView.vue'),
            meta: { title: '标签维护', icon: 'discount', Code: 'config_tag:view' }
        },
        {
            path: 'attributeView',
            name: 'configAttribute',
            component: () =>
                import ('@/views/config/attributeView.vue'),
            meta: { title: '属性维护', icon: 'suggest', Code: 'config_attribute:view' }
        },
        {
            path: 'taskConfigView',
            name: 'configTaskConfig',
            component: () =>
                import ('@/views/config/taskConfigView.vue'),
            meta: { title: '任务配置', icon: 'manage-order', Code: 'config_task_config:view' }
        },
        {
            path: 'taskAllocationView',
            name: 'configTaskAllocation',
            component: () =>
                import ('@/views/config/taskAllocationView.vue'),
            meta: { title: '任务分配', icon: 'rejected-order', Code: 'config_task_allocation:view' }
        },
        {
            path: 'commonRuleView',
            name: 'configCommonRule',
            component: () =>
                import ('@/views/config/commonRuleView.vue'),
            meta: { title: '公海落入规则', icon: 'logistic-logo-fill', Code: 'config_common_rule:view' }
        },
        {
            path: 'commonConfigView',
            name: 'configCommonConfig',
            component: () =>
                import ('@/views/config/commonConfigView.vue'),
            meta: { title: '公海配置', icon: 'logistic-logo', Code: 'config_common:view' }
        },
        {
            path: 'privacyLogView',
            name: 'privacyLog',
            component: () =>
                import ('@/views/config/privacyLogView.vue'),
            meta: { title: '隐私日志', icon: 'manage-order', Code: 'privacy:view' }
        },
        {
            path: 'scoreConfigView',
            name: 'configScoreConfig',
            component: () =>
                import ('@/views/config/scoreConfigView.vue'),
            meta: { title: '得分项配置', icon: 'confirm', Code: 'config_score:view' }
        },
        {
            path: 'requiredFieldsView',
            name: 'requiredFieldsView',
            component: () =>
                import ('@/views/config/requiredFieldsView.vue'),
            meta: { title: '必填项配置', icon: 'asterisk', Code: 'config_required:view' }
        },
        {
            path: 'requiredFieldsGroupView',
            name: 'requiredFieldsGroup',
            component: () =>
                import ('@/views/config/requiredFieldsGroupView.vue'),
            meta: { title: '必填项分组', icon: 'asterisk', Code: 'config_requiredFieldsGroup:view' }
        },
        // {
        //   path: 'requiredFieldsGroupEditView',
        //   name: 'requiredFieldsGroupEditView',
        //   component: () => import('@/views/config/requiredFieldsGroupEditView.vue'),
        //   meta: {title: '必填项分组新增',icon: 'asterisk',  Code: 'config_requiredFieldsGroupEdit:view'}
        // },
        {
            path: 'requiredFieldsGroupEditView',
            name: 'requiredFieldsGroupEditView',
            hidden: true,
            component: () =>
                import ('@/views/config/requiredFieldsGroupEditView.vue'),
            meta: { title: '必填项分组新增', Code: 'config_requiredFieldsGroupEdit:view' }
        },
        {
            path: 'commonConfigAddView',
            name: 'CommonConfigAdd',
            hidden: true,
            component: () =>
                import ('@/views/config/modules/commonConfig/CommonConfigEdit.vue'),
            meta: { title: '公海配置新增', Code: 'config_required:add' }
        },
        {
            path: 'commonConfigEditView',
            name: 'CommonConfigEdit',
            hidden: true,
            component: () =>
                import ('@/views/config/modules/commonConfig/CommonConfigEdit.vue'),
            meta: { title: '公海配置编辑', Code: 'config_required:edit' }
        }
    ]
}

export default configRouter