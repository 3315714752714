<template>
  <div class="chang-pwd-box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="原密码" prop="oldPwd">
        <el-input v-model="ruleForm.oldPwd" class="form-item-input" show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPwd">
        <el-input v-model="ruleForm.newPwd" class="form-item-input" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPwd">
        <el-input v-model="ruleForm.confirmPwd" class="form-item-input" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { changePwd } from '@/api/user'
export default {
  data() {
    return {
      ruleForm: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: ''
      },
      rules: {
        oldPwd: [
          { required: true, message: '请输入旧密码' }
        ],
        newPwd: [
          { required: true, message: '请输入新密码' }
        ],
        confirmPwd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { required: true, validator: this.checkConfirmPwd }
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { oldPwd, newPwd } = this.ruleForm
          const data = {
            password: oldPwd,
            newPassword: newPwd
          }
          changePwd(data).then(res => {
            this.close()
            this.$message.success("密码修改成功")
          })
        } else {
          return false
        }
      })
    },
    checkConfirmPwd(rule, value, callback) {
      if (value !== this.ruleForm.newPwd) {
        callback('两次密码不一致')
        return false
      }
      callback()
    },
    close() {
      this.$emit('on-close')
      this.ruleForm = {
        oldPwd: '',
        newPwd: '',
        confirmPwd: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.form-item-input {
  width: 250px;
}

</style>
