<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setLogin } from "./utils/login";
import { setInitStoreData } from "./utils/store";

export default {
  name: "App",
  methods: {
    //  监听客户端方法
    onWatchClientRouter() {
      window.addEventListener("navigate", (e) => {
        let { url } = e;
        if (!url) {
          return;
        }
        this.$router.push(url);
      });
    },
  },

  async created() {
    this.$isClient && (await setLogin());
    await setInitStoreData();
    if (this.$isClient) {
      var path = window.location.pathname
      
      if (path.indexOf("/#") === 0) {
        path = path.substr(2);
      } 
      this.$router.push(path);
    }
    await this.onWatchClientRouter();
  },

  async mounted() {
    //await this.onWatchClientRouter();
  }
};
</script>
