import Layout from '@/layout/index.vue'
const accountRouter = {
    path: '/account',
    component: Layout,
    redirect: 'noRedirect',
    meta: { title: '账户管理', icon: 'user_center', Code: 'account_manage:view' },
    children: [{
            path: '/user',
            component: () =>
                import ('@/views/account-manage/user'),
            name: 'user',
            meta: { title: '用户管理', icon: 'account', Code: 'account_manage_user:view' }
        },
        {
            path: '/role',
            component: () =>
                import ('@/views/account-manage/role'),
            name: 'role',
            meta: { title: '角色管理', icon: 'Customer_management', Code: 'account_manage_roles:view' }
        },
        {
            path: '/role-edit',
            component: () =>
                import ('@/views/account-manage/role-edit'),
            name: 'role-edit',
            hidden: true,
            meta: { title: '角色编辑', Code: 'account_manage_roles:edit' }
        }
    ]
}

export default accountRouter