<template>
  <el-container>
    <el-main class="body-box">
      <div class="right">
        <router-view/>
      </div>
    </el-main>
  </el-container>
</template>

<script>
// import MainView from './components/MainView'
const initYear = 2021
export default {
  components: {},
  computed: {
    text() {
      const date = new Date()
      if (date.getFullYear() <= initYear) {
        return initYear
      }
      return initYear + '-' + date.getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>

.el-container {
  height: 100%;
}
.body-box {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  .right {
    background: url("~assets/img/background.png") no-repeat center;
    background-size: auto 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer-box {
  text-align: center;
  line-height: 30px;
  padding: 22px 0;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  min-width: 1000px;
}
</style>
