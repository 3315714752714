<template>
  <div>
    <p>
      <span style="display: inline-block; margin-right:5px">代理费</span>
      <el-checkbox v-model="checked" @change="onCheckBox"></el-checkbox>
    </p>
  </div>
</template>
<script>
export default {
  name: 'GridCellRadioHeaderRender',
  data () {
    return {
      checked: false
    }
  },
  methods: {
    onCheckBox () {
      this.params.onChange(this.checked)
    }
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.checked = this.params.initValue
    })
  }
}
</script>
