<template>
  <div>
    <p v-if="params.topText" style="color: #000000">{{params.topText}}</p>
    <p v-if="params.downText" style="color: #888888">{{params.downText}}</p>
    <p v-if="params.threeText" style="color: #888888">{{params.threeText}}</p>
  </div>
</template>
<script>
  export default {
    name: 'GridHeaderTitleRender',
    data() {
      return {

      }
    }
  }
</script>
