<template>
  <div class="p-grid-show">
    <el-tooltip placement="bottom-end" effect="light">
      <div>{{params.value}}</div>
      <div slot="content">
        <p v-for="(item,index) in lines" :key="index">
          <span>{{item}}</span>
        </p>
      </div>
    </el-tooltip>
  </div>
</template>
<script>
  import Vue from 'vue';
  import {Tooltip} from 'element-ui';

  Vue.use(Tooltip)

  export default {
    name: 'GridCellShowRender',
    data () {
      return {
        data: 0,
        lines: []
      }
    },
    computed: {},
    methods: {
      setLines (value) {
        const newValue = value.split(',')
        this.lines = newValue
      }
    },
    created () {
      this.data = this.params.initData
      this.setLines(this.params.value)
    }
  }
</script>
<style lang="scss">
  .p-grid-show {
    .el-tooltip {
      width: 100%;
    }
  }


</style>
