import request from '@/utils/request'
import {nativeInvoke, isClient} from '@/utils/erp-client'
export function getSystemInfo(body) {
  const data  = body || {Page:0, Limit:0}
  return request({
    url: 'SystemInfo/GetUserList',
    method: 'post',
    data
  })
}

export function getSystemDepart() {
  const data  = {Page:0, Limit:0}
  return request({
    url: 'SystemInfo/GetDepartList',
    method: 'post',
    data
  })
}

export function getSystemAuthUsers(data) {
		return request({
				url: 'SystemInfo/FindUserList',
				method: 'post',
				data
		})
}

export async function downFile(fileParam) {
  if (isClient()) {
    const downloadFileReq = 'downloadFile'
    const res = nativeInvoke(downloadFileReq, fileParam)
    return res
  } else {
    (function () {
      // var url = 'ftp://192.168.1.10/dist/erp/fiddler.zip'
      let url = fileParam.FileUrl
      window.el = document.createElement('a')
      window.el.href = url
      window.el.target = '_blank'
      window.el.download = url.substr(url.lastIndexOf('/') + 1)
      document.body.appendChild(window.el)
      window.el.click()
      document.body.removeChild(window.el)
    })()
    return {FileName: fileParam.FileUrl}
  }
}
