import LoginLayout from '@/views/login/index'
const loginRouter = {
	path: '/login',
	component: LoginLayout,
	hidden: true,
	redirect: 'noRedirect',
	children: [
		{
			path: '/login',
			component: () => import('@/views/login/Login'),
			name: 'login'
		},
		{
			path: '/register',
			component: () => import('@/views/login/Register'),
			name: 'register'
		},
		{
			path: '/forgot-password',
			component: () => import('@/views/login/RestPassWord'),
			name: 'forgotPassword'
		}
	]
}

export default loginRouter
