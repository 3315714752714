import Cookies from 'js-cookie'
const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, time) {
  return Cookies.set(TokenKey, token, {
  	expires: new Date(time)
  })
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

export function initToken() {
	return Cookies.set(TokenKey, '')
}
