import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken} from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

import {isClient} from './utils/erp-client'
import {setLogin} from "./utils/login";

NProgress.configure({showSpinner: false})

const whiteList = ['/login', '/auth-redirect', '/register', '/forgot-password']

router.beforeEach(async (to, from, next) => {


  NProgress.start()
  document.title = getPageTitle(to.meta.title)

  let hasToken = await getToken()

  do {
    if (hasToken) {
      if (to.path === '/login') {
        next({path: '/'})
        break
      }
      next()
      break
    }

    if (whiteList.indexOf(to.path) !== -1) {
      next()
      break
    }

    if (!isClient()) {
      next(`/login?redirect=${to.path}`)
      break
    }



    // 如果在客户端并且未登录
    // next()

    // if (isClient()) {
    //   // await setLogin()
    //   // return next({...to, replace: true})
    //   next()
    //   break
    // }


  } while (false)

  NProgress.done()
})

router.afterEach(async () => {
  // finish progress bar
  // await setInitStoreData()
  NProgress.done()
})
