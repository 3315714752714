<template>
  <div class="c-grid-cell-select-title" @mouseenter="onEnter" @mouseleave="onLeave">
    <section class="c-grid-cell-select-title-box">
      <div style="width: 100%;text-align: left;">
        <span :title="value" >{{ value }}</span>
      </div>
      <div v-show="isShow" class="c-grid-cell-select-title-box_checked" style="z-index: 4">
        <el-checkbox v-model="params.data[`isSelect${params.colDef.field}`]" @change="onSelect"></el-checkbox>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'GridCellSelectTitle',
  data() {
    return {
      checked: false,
      isShow: false,
      value: '',
      field: '',
      code: ''
    }
  },
  methods: {
    onEnter() {
      this.isShow = true
    },
    onLeave() {
      const {checked} = this
      if (checked) return
      this.isShow = false
    },
    onSelect(val) {
      const {data, colDef} = this.params
      const params = {
        index: data.rowIndex,
        code: colDef.field,
        isCheck: val
      }
      this.params.onChange(params)
    }
  },
  created() {
    const {data, value, colDef} = this.params
    this.data = data
    this.value = value
    this.field = colDef.field

    const code = `isSelect${this.field}`
    this.code = code
    this.checked = data[code]

    this.isShow = data[code]
  },
  mounted() {}
}
</script>
<style lang="scss">
.c-grid-cell-select-title {
  width: 100%;
  height: 30px;
  //border: 1px solid #cccccc;
  font-size: 12px;

  .c-grid-cell-select-title-box {
    width: 100%;
    height: 100%;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .c-grid-cell-select-title-box_checked {
    position: absolute;
    left: 50%;
    //.el-checkbox__inner {
    //  background-color: transparent;
    //}

  }

}
</style>
