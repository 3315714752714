import {setErpSignIn} from "@/api/user";
import {setToken, initToken} from "./auth";
import { isClient, getErpAuthInfo } from "./erp-client";

import {setInitStoreData} from "@/utils/store";

export async function setLogin() {
  if (isClient()) {
    const authInfo = await getErpAuthInfo();
    localStorage.setItem('EroUserInfo', authInfo);

    const val = await setErpSignIn(authInfo)

    const { IsSuccess, Data } = val
    if (!IsSuccess) return
    setToken(Data['AccessToken'])


  } else {
    console.log('web login')
    initToken()
    // this.$router.push({path:'/login'})
  }
}




