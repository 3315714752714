function getUrl(id, raiseEmpty = true) {
  const el = document.getElementById(id)
  if (!el) {
    throw new Error(`没有找到URL配置的HTML节点: #${id}`)
  }

  const url = el.innerHTML.trim()
  if (raiseEmpty && !url) {
    throw new Error(`URL配置的HTML节点不可为空: #${id}`)
  }

  return url
}

export const getApiBaseUrl = () => getUrl('apiBaseUrl')
