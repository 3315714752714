<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" v-if="!isErp" @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
<!--      <template v-if="device!=='mobile'">-->
<!--        <div class="errLog-container right-menu-item hover-effect" style="font-size:12px;">{{ companyName }}</div>-->
<!--        <el-tooltip content="全屏" effect="dark" placement="bottom">-->
<!--          <screen-full id="screenfull" class="right-menu-item hover-effect" />-->
<!--        </el-tooltip>-->
<!--      </template>-->

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img v-if="avatarUrl" :src="avatarUrl+'?imageView2/1/w/80/h/80'" alt="" class="user-avatar">
          <!-- <img :src="'images/default.jpg'" alt="" class="user-avatar"> -->
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item style="text-align: center">
            <span style="display:block;">{{ nickName }}</span>
          </el-dropdown-item>
          <el-dropdown-item style="display: flex;justify-content: center;" divided @click.native="changePassword">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item style="display: flex;justify-content: center;" divided @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog v-el-drag-dialog title="修改密码" :close-on-click-modal="false" :visible.sync="changePwdDisplay" width="400px">
      <ChangePassWord @on-close="closeChangePwd" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
// import ScreenFull from '@/components/Screenfull'
import ChangePassWord from './ChangePassWord'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    // ScreenFull,
    ChangePassWord
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'nickName',
      'avatarUrl',
      'device',
      'companyName'
    ]),
    isErp() {
      return this.$store.state.entrance.entranceType === 'erp'
    }
  },
  data() {
    return {
      changePwdDisplay: false
    }
  },
  created() {

  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    changePassword() {
      this.changePwdDisplay = true
    },
    closeChangePwd() {
      this.changePwdDisplay = false
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      // 保留第一个导航
      this.$store.state.tagsView.visitedViews=this.$store.state.tagsView.visitedViews.slice(0,1)
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .notification-container {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
