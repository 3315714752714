import request from '@/utils/request'

// 通用字典 star
export function getDictList(data) {
	return request({
		url: 'Dict/GetDicts',
		method: 'post',
		data
	})
}

export function getDict(data) {
	return request({
		url: 'Dict/GetDictItems',
		method: 'post',
		data
	})
}

export function getDictValueList(params) {
	return request({
		url: 'Dict/GetInfoeMain',
		method: 'get',
		params
	})
}

export function saveDict(data) {
	return request({
		url: 'Dict/EditOrInsertDictMain',
		method: 'post',
		data
	})
}

export function deleteDict(data) {
	return request({
		url: 'Dict/DeleteDictMain',
		method: 'post',
		data
	})
}

export function GetSorts(data) {
		return request({
				url: 'Dict/GetSorts',
				method: 'post',
				data
		})
}

export function SaveSort(data) {
		return request({
				url: 'Dict/SaveSort',
				method: 'post',
				data
		})
}
// end


// 标签维护 star
export function getTagList(data) {
  return request({
    url: 'LabelMaintenance/GetList',
    method: 'post',
    data
  })
}

export function getTagValueList(params) {
  return request({
    url: 'LabelMaintenance/Get',
    method: 'post',
    params
  })
}

export function saveTag(data) {
  return request({
    url: 'LabelMaintenance/Add',
    method: 'post',
    data
  })
}

export function editTag(data) {
  return request({
    url: 'LabelMaintenance/Edit',
    method: 'post',
    data
  })
}

export function deleteTag(params) {
  return request({
    url: 'LabelMaintenance/Delete',
    method: 'get',
    params
  })
}

// end

// 属性维护 star
export function getAttributeList(data) {
  return request({
    url: 'AttributeMaintenance/GetAttributes',
    method: 'post',
    data
  })
}

export function getAttributeValueList(params) {
  return request({
    url: 'AttributeMaintenance/GetInfoeMain',
    method: 'get',
    params
  })
}

export function saveAttribute(data) {
  return request({
    url: 'AttributeMaintenance/EditOrInsertAttributeMain',
    method: 'post',
    data
  })
}

export function editAttribute(data) {
  return request({
    url: 'AttributeMaintenance/EditOrInsertAttributeMain',
    method: 'post',
    data
  })
}

export function deleteAttribute(data) {
  return request({
    url: 'AttributeMaintenance/DeleteAttributesMain',
    method: 'post',
    data
  })
}

// end


// 隐私日志 star
export function GetprivacyLogInfo(data) {
	return request({
		url: 'Customer/GetprivacyLogInfo',
		method: 'post',
		data
	})
}
// end

// 必填项分组 star
export function GetrequiredFieldsGroup(data) {
	return request({
		url: 'Customer/GetrequiredFieldsGroup',
		method: 'post',
		data
	})
}
// end


// 任务配置 star
export function GetTasksetInfo(data) {
	return request({
		url: 'TaskMaintenance/GetTasksetInfo',
		method: 'post',
		data
	})
}

export function getTaskConfigList(params) {
  return request({
    url: 'TaskMaintenance/GetCFG_TaskinfoList',
    method: 'get',
    params
  })
}

export function saveTaskConfig(data) {
  return request({
    url: 'TaskMaintenance/InsertOrUpdate',
    method: 'post',
    data
  })
}

export function editTaskConfig(data) {
  return request({
    url: 'TaskMaintenance/InsertOrUpdate',
    method: 'post',
    data
  })
}

export function geTaskTypes(params){
  return request({
    url: 'TaskMaintenance/GeTaskTypes',
    method: 'get',
    params
  })
}

// end


// 任务分配 start
export function getTaskAllocationList(data) {
  return request({
    url: 'TaskMaintenance/GetTaskset',
    method: 'post',
    data
  })
}

export function DeleteDispatchTaskSet(data) {
		return request({
				url: 'TaskMaintenance/DeleteDispatchTaskSet',
				method: 'post',
				data
		})
}

export function getTaskAllocationInfo(data) {
  return request({
   //url: 'TaskMaintenance/GetTasksetInfo',
    url: 'TaskMaintenance/GetTasksetInfo',
    method: 'post',
    data
  })
}


export function editTaskAllocation(data) {
  return request({
    url: 'TaskMaintenance/Taskset',
    method: 'post',
    data
  })
}

// 公海落入 start
export function getCommonRuleList(data) {
  return request({
    url: 'OpenSeaMaintenance/GetRuleList',
    method: 'post',
    data
  })
}
export function saveCommonRule(data) {
  return request({
    url: 'OpenSeaMaintenance/AddRule',
    method: 'post',
    data
  })
}
export function editCommonRule(data) {
  return request({
    url: 'OpenSeaMaintenance/EditRule',
    method: 'post',
    data
  })
}
export function deleteCommonRule(params) {
  return request({
    url: 'OpenSeaMaintenance/DeleteRule',
    method: 'get',
    params
  })
}

//公海配置  start
export function getCommonConfigList(data) {
  return request({
    url: 'OpenSeaMaintenance/GetPoolList',
    method: 'post',
    data
  })
}
export function deleteCommonConfig(params){
  return request({
    url: 'OpenSeaMaintenance/DeletePool',
    method: 'get',
    params
  })
}
export function getCommonConfig(params) {
  return request({
    url: 'OpenSeaMaintenance/GetPool',
    method: 'get',
    params
  })
}
export function distribution(data) {
	return request({
		url: 'SeaManger/DistributionClient',
		method: 'post',
		data
	})
}
export function transfer(data) {
	return request({
		url: 'SeaManger/TransferClient',
		method: 'post',
		data
	})
}
export function getAllCommonConfig() {
  return request({
    url: 'OpenSeaMaintenance/GetAllSeaList',
    method: 'Post',
  })
}
export function getAllCommonRule() {
  return request({
    url: 'OpenSeaMaintenance/GetAllRuleList',
    method: 'Post',
  })
}
export function GetDepartmentList(data) {
  return request({
    url: 'OpenSeaMaintenance/GetDepartmentList',
    method: 'Post',
    data
  })
}

export function commonConfigAdd(data) {
  return request({
    url: 'OpenSeaMaintenance/AddPool',
    method:'post',
    data
  })
}

export function commonConfigEdit(data) {
  return request({
    url: 'OpenSeaMaintenance/EditPool',
    method: 'post',
    data
  })
}
//end


//得分项配置  start
export function scoreConfigGetList(params){
  return request({
    url:"ScoreMaintenance/GetScoreItemList",
    method:"post",
    params
  })
}

//得分项分组配置  start
export function GetScoreGroupItemList(params){
  return request({
    url:"ScoreMaintenance/GetScoreGroupItemList",
    method:"post",
    params
  })
}

export function scoreConfigEdit(data){
  return request({
    url:"ScoreMaintenance/AddcoreItem",
    method:"post",
    data
  })
}


export function scoreGroupConfigEdit(data){
  return request({
    url:"ScoreMaintenance/scoreGroupConfigEdit",
    method:"post",
    data
  })
}


export function DelscoreGroupConfig(params){
  console.log(params)
  return request({
    url:"ScoreMaintenance/DelscoreGroupConfig",
    method:"get",
    params
  })
}
//end

