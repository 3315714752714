<template>
  <div class="p-grid-list-date">
    <el-date-picker
      v-model="data"
      :clearable="true"
      type="date"
      format="yyyy/MM/dd"
      class="c-date"
      @change="onChange"
      placeholder="时间">
    </el-date-picker>
  </div>
</template>
<script>
  export default {
    name: 'GridDateRender',
    data() {
      return {
        data: '',
      }
    },
    computed: {},
    methods: {
      onChange() {
        const index = this.params.data.RowIndex
        this.params.onChange(this.data, index)
      }
    },
    created() {
      this.data = this.params.value
    }
  }
</script>
<style lang="scss">
  .p-grid-list-date {
    width: 100%;

    .el-input__prefix {
      display: none;
    }
    .el-input__inner {
      padding: 0 !important;
      width: 100% !important;
      height: 100%  !important;
      border-radius: 0;
      font-size: 10px;
      border: none;
    }
    .el-input__icon::before{
      position: absolute !important;
      top: -8px !important;
      right: -3px !important;
    }
  }

  .p-grid-title {
    text-align: left;

  }

  .d {
    /*display: none;*/
  }

</style>
