import store from "../store";
import router from "../router";
import {getToken} from "./auth";

export async function setInitStoreData() {

  const hasToken = getToken()
  if (!hasToken) return false
  console.log('存储公用数据')
  const {permissions} = await store.dispatch('user/getInfo')
  await store.dispatch('common/setSystemUser')
  await store.dispatch('common/setSystemDepart')
  await store.dispatch('common/setCurrencyOptions')
  await store.dispatch('common/setTaxRatesOptions')


  const accessRoutes = await store.dispatch('permission/generateRoutes', permissions)
  router.addRoutes(accessRoutes)
}
