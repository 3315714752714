<template>
  <div class="p-grid-select">
    <div class="p-grid-title">
      <p class="p-grid-text">
        <span>{{textOne}}</span>
        <span style="text-align: center" v-if="isMultiRow">{{textTwo}}</span>
      </p>
      <p v-if="isSort" @click="handleSortClick" class="w-sort">
        <i :class="isSortType==1? 'el-icon-caret-top w-active': 'el-icon-caret-top'"></i>
        <i :class="isSortType==2? 'el-icon-caret-bottom w-active': 'el-icon-caret-bottom'"></i>
      </p>
    </div>
    <select class="select-box" name="select" @change="onChange" v-model="data">
      <option v-for="(item,index) in params.mapOption" :value="item.key" :key="index">{{item.value}}</option>
    </select>
  </div>
</template>
<script>
  export default {
    name: 'GridRadioRender',
    data () {
      return {
        data: 0,
        isSort: false,
        isSortType: 0,
        isMultiRow: false,
        textOne: '',
        textTwo: ''
      }
    },
    computed: {},
    methods: {
      onChange () {
        this.params.onChange(this.data)
      },
      // 0:无排序 1.升序 3.降序
      handleSortClick () {
        const num = this.isSortType
        if (num === 0) {
          this.isSortType = 2
        } else if (num === 2) {
          this.isSortType = 1
        } else if (num === 1) {
          this.isSortType = 0
        }
        this.params.onSortChange(this.isSortType)
      },

      // 处理头部分行
      setHeaderText(data) {
        const isMultiRow = data.indexOf("&") !== 1
        if(isMultiRow) {
          const newData = data.split('&')
          this.textOne = newData[0]
          this.textTwo = newData[1]
          this.isMultiRow = isMultiRow
        } else {
          this.textOne = data
        }


      }
    },
    created () {
      this.data = this.params.initData
      this.isSort = this.params.isSort
      this.isSortType = this.params.isSortType

      this.setHeaderText(this.params.displayName)
    }
  }
</script>
<style lang="scss">
  .p-grid-select {
    width: 100%;
    .ag-header-cell {
      line-height: 1 !important;
    }

  }

  .select-box {
    border-color: #d5e2e0;
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, .65);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    font-weight: bold;
    option {
      color: black;
      line-height: 20px;
      border: none;
    }
  }

  .p-grid-title {
    text-align: left;
    display: flex;
    /*margin-bottom: 3px;*/


    .w-sort {
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      margin-left: 2px;
      display: flex;
      flex-direction: column;
      color: #c3c3c3;
      justify-content: center;
    }
    .w-active {
      color: #15a5f7;
    }
    .el-icon-caret-top, .el-icon-caret-bottom {
      width: 10px;
      height: 10px;
    }

  }

  .p-grid-text {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

</style>
