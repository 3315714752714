<template>
  <div>
    <znl-button
      type="text"
      v-for="(item, index) in buttons"
      :key="index"
      @click="() => item.click(params)">
      <span :class="item.className? item.className: ''">{{item.text}}</span>
    </znl-button>
  </div>
</template>
<script>
  export default {
    name: 'GridCellButtonRender',
    computed: {
      buttons() {
        return typeof this.params.buttons === 'function' ? this.params.buttons(this.params) : this.params.buttons
      }
    }
  }
</script>
