import {getInfo} from '@/api/user'
import {removeToken} from '@/utils/auth'
import {resetRouter} from '@/router'

const state = {
  userInfo: {},
  permissions: [],
  permissionMap: {}
}

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = Object.assign(state, userInfo)
    state.userInfo.avatarUrl = state.userInfo.avatarUrl || 'images/default-user.jpg'
  },
  SET_PERMISSION: (state, permissions) => {
    state.permissions = permissions
  },
  SET_PERMISSION_MAP: (state, permissionMap) => {
    state.permissionMap = permissionMap
  }
}

const actions = {
  // get user info
  getInfo({commit}) {
    return new Promise((resolve, reject) => {
      getInfo().then(data => {
        const {permissions} = data
        const permissionMap = permissions.reduce((map, {Code}) => (map[Code] = true, map), {})

        commit('SET_PERMISSION_MAP', permissionMap)
        commit('SET_PERMISSION', permissions)
        commit('SET_USER_INFO', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  logout({commit, dispatch}) {
		  dispatch('tagsView/delAllViews')
    removeToken()
    resetRouter()
  },

  resetToken({commit}) {
    return new Promise(resolve => {
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
